<script setup>
import { generateUid } from '~~/utils/uid'

const uid = generateUid()

const attrs = useAttrs()

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  animateOnMount: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: null,
  },
  tag: {
    type: String,
    default: 'button',
  },
  showHighlight: {
    type: Boolean,
    default: false,
  },
  rotateHighlight: {
    type: Boolean,
    default: false,
  },
})

const hasMounted = ref(!props.animateOnMount)
onMounted(async () => {
  if (props.animateOnMount) {
    await nextTick()
    hasMounted.value = true
  }
})
</script>

<template>
  <component
    :is="attrs.href ? 'a' : tag"
    class="button-icon"
    :class="{
      'button-icon--open': isOpen && hasMounted,
      'button-icon--show-highlight': showHighlight,
      'button-icon--rotate-highlight': rotateHighlight,
    }"
  >
    <slot name="icon">
      <Icon class="button-icon__icon" :name="icon" />
    </slot>

    <slot />

    <div class="button-icon__background" />
    <svg viewBox="-21 -21 42 42" class="button-icon__svg" aria-label="Button Icon" aria-labelledby="buttonTitle buttonTitleDesc" role="img">
      <title id="buttonTitle">Button Icon</title>
      <desc id="buttonTitleDesc">An illustrated Button Icon.</desc>
      <defs>
        <clipPath :id="`outline-clip-${uid}`">
          <circle cx="0" cy="0" r="21" />
        </clipPath>

        <linearGradient
          :id="`highlight-gradient-${uid}`"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="0%" stop-color="#ffffff" stop-opacity="0.4" />
          <stop offset="25%" stop-color="#ffffff" stop-opacity="0" />
        </linearGradient>
      </defs>

      <g class="button-icon__highlight-group">
        <circle
          class="button-icon__highlight button-icon__highlight--1"
          cx="0"
          cy="0"
          r="21"
          :stroke="`url(#highlight-gradient-${uid})`"
          :clip-path="`url(#outline-clip-${uid})`"
        />
        <circle
          class="button-icon__highlight button-icon__highlight--2"
          cx="0"
          cy="0"
          r="21"
          :stroke="`url(#highlight-gradient-${uid})`"
          :clip-path="`url(#outline-clip-${uid})`"
        />
      </g>
    </svg>
  </component>
</template>

<style lang="scss">
.button-icon {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: transform 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    position: relative;
    z-index: 1;
    pointer-events: none;
    transition: transform 0.3s;
  }

  &__svg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    pointer-events: none;
  }

  &__background {
    transition: opacity 0.3s, background 0.3s;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: linear-gradient(110.59deg, #c80afb 7.52%, #2919a9 153.34%);
    position: absolute;
    z-index: -1;
  }

  &__highlight {
    stroke-width: 3px;
    fill: transparent;
    opacity: 0;
    &--2 {
      transform: rotate(180deg);
    }
    &-group {
      transition: transform 0.5s ease, opacity 0.5s ease;
    }
  }

  &:disabled {
    cursor: default;

    .button-icon__icon {
      color: #82658e;
    }

    .button-icon__background {
      background: #2c143b;
    }
  }

  &--open {
    .icon {
      transform: rotate(45deg);
    }
  }

  &--open,
  &--show-highlight {
    .button-icon {
      &__background {
        opacity: 0;
      }
      &__highlight {
        opacity: 1;
      }
      &__highlight-group {
        transform: rotate(135deg);
      }
    }

    &:hover {
      .button-icon {
        &__highlight-group {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &--open,
  &--rotate-highlight {
    .button-icon {
      &__highlight-group {
        transform: rotate(315deg);
      }
    }
    &:hover {
      .button-icon {
        &__highlight-group {
          transform: rotate(135deg);
        }
      }
    }
  }
}
</style>
